// 售后商品信息组件 @ljp-2021/1/4
<template>
    <!-- 退款商品信息 start -->
    <div class="sld_refund_goods flex_row_between_start">
        <div class="refund_goods_left flex_row_start_start">
            <div class="refund_goods_img" :style="{ backgroundImage: 'url(' + refundOrderDetail.goodsImage + ')' }"></div>
            <div class="refund_goods_des">
                <p>{{ refundOrderDetail.goodsName }}</p>
                <p>{{ refundOrderDetail.specValues }}</p>
                <br />
                <p v-if="refundOrderDetail.orderCode">{{L['订货编码：']}}{{refundOrderDetail.orderCode}}</p>

            </div>
        </div>
        <div class="refund_goods_num">*{{ refundOrderDetail.currNum ? refundOrderDetail.currNum : refundOrderDetail.buyNum }}
        </div>
    </div>
    <!-- 退款商品信息 end -->
</template>

<script>
import { getCurrentInstance } from "vue";
export default {
    props: {
        refundOrderDetail: {
            type: Object,
            value: {}
        }
    },
    setup() {
        const { proxy } = getCurrentInstance();
            const L = proxy.$getCurLanguage()
            return{L}
    }
}
</script>

<style lang="scss">
.sld_refund_goods {
    padding: 20px;

    .refund_goods_left {
        .refund_goods_img {
            width: 100px;
            height: 100px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
        }

        .refund_goods_des {
            margin-left: 20px;

            p {
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
                text-overflow: -o-ellipsis-lastline;
                line-clamp: 2;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }

            p:nth-child(1) {
                width: 444px;
                line-height: 18px;
                color: #333333;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                margin-top: 8px;
                margin-bottom: 20px;
            }

            p:nth-child(2) {
                width: 444px;
                color: $colorH;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                margin-top: 18px;
            }
        }
    }

    .refund_goods_num {
        width: 100px;
        text-align: center;
        line-height: 100px;
        font-size: 12px;
    }
}
</style>